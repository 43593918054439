import React, { useState, useEffect } from "react";
import "./Rules.scss";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function Rules() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-rule">
        <div className="title">Terms and Conditions</div>
        <p>
        Etihad Communication Online Store means that you use your full agreement with the following terms and conditions:
           <br />
           1-1- Purchasing goods from Etihad's online store is based on the laws and regulations in e-commerce and in full compliance with all laws of the Islamic Republic of Iran.
          <br />
          1-2- Services and contents of this site for personal and non-commercial use of customers
 It is provided and the intellectual property of the information contained in it belongs to it and any misuse of this information is prosecuted. Non-commercial use of the contents of the site is unrestricted by mentioning the source, and if you want to use the information on the site for commercial purposes, you must send your request via info@rabetettehad.ir and obtain written permission.
          <br />
          1-3- The information provided regarding the technical specifications of the goods provided by technical experts and obtained from the manufacturing companies of the contracting party, the information provided in the Etihad interface online store may be inaccurate or have typographical errors, the online store Responsible alliance interface
  The information provided will not be accepted. This store will constantly review and update its information and contents.
          <br />
         
          <br />
          1-4- Users are required to provide complete correct information when ordering goods
  Insert the base. Obviously, lack or inaccuracy of information will prevent the correct and timely completion of the order.
          <br />
          1-6- All the tools that are used in Etihad interface online store with the aim of communicating with customers and are designed only for this purpose.
 Users are committed to using this tool solely for sending and receiving information
 Use that is appropriate and relevant to the product order discussion.
          <br />
          1-7- Other sites and links to provide more and more complete information to
 Alliance online store link links have been independent, and store
 Internet Alliance interface is not responsible for their contents.
          <br />
          1-8-Communication of Etihad interface online store with users through www.rabetettehad.ir
 And is done only using the information that users have entered on the site (such as address, phone, etc.).
          <br />
          1-9- Etihad interface online store will not disclose the user's unique information to third parties and at the same time is committed to using the latest technologies to protect the privacy of users as much as possible.
          <br />
        </p>
        <hr />
        <div className="title">Terms and conditions of goods sales services</div>
        <p>
        From a legal point of view, in the transactions of Etihad's online store, the site www.rabetettehad.ir is considered as the seller and the ordering internet user is considered as the buyer.
          <br />
          2-1- The sale of goods is based on the type of products and the number that the buyer has entered in the online store of Etihad.
          <br />
          2-2- Seller, specifications and time before the buyer registers the order of the goods
 Determines the delivery of goods and notifies the buyer. Also all the steps
  Will be coordinated with the buyer by e-mail or SMS until delivery.
          <br />
          The value of the goods or goods ordered is calculated according to the site price, which will be calculated by deducting the product discount, if any.
          <br />
          2-3- Place and time of delivery of goods by the buyer in the Etihad interface online store
  It is listed and this store, as the seller, is committed to deliver the order at that place and time, in compliance with certain shipping policies. The absence of the customer at the specified place and time, the seller will apply the relevant special policies.
          <br />
          2-4- If the seller, for reasons beyond his control, is not able to deliver the goods on time and the parties do not agree on a new date, each party has the right to cancel the order at no cost. And withdraw the deposit in accordance with the specific policies related to the return of goods.
        </p>
        <hr />
        <div className="title">Terms of transport and delivery of goods</div>
        <p>
        Orders are sent throughout Iran.
          <br />
          Product delivery time is stated in the product description. In addition, paying attention to the relevant time is one working day after depositing the money and issuing the invoice. (Official holidays will not be calculated) The place of delivery of the goods is the address that you, dear customer, enter on the website while ordering. This address is the criterion of the store's shipping and logistics officers, so if you change the address, you must inform our experts immediately.
          <br />
          The delivery time of the purchased goods depends on the time of your presence in Ares, which will be coordinated with you in advance and can be changed according to your offer.
          <br />
          The products sent to the esteemed buyers are packaged according to the type of products and its installation is the responsibility of the buyer.
        </p>
        <hr />
        <div className="title">Terms and conditions of after-sales service</div>
        <p>
        A) Return of goods:
          <br />
          A - 1) Before sending the goods: In case of cancellation of receiving the goods after settlement
 Account and before sending the goods, the entire amount will be refunded to the buyer's bank account.
          <br />
          A-2) After sending the goods: The customer does not have the right to cancel and the purchased from the date of delivery of the goods and as soon as the right of cancellation is used before the delivery of the product from the shipping agents,
  Without claiming any amount the same amount received as soon as possible in accordance with
  The policies of the financial unit will refund you and the only cost imposed on the customer will be the cost of shipping and sending the goods. (Article 37 of the Commercial Code
          Electronic)
          <br />
          A-3) Etihad interface online store for returned goods (taste) for dear customers has set a time period (7 days) for the desired product.
          <br />
          <br />
          B) Warranty
                    <br />
                    Warranty period and after-sales service of all goods offered on the site for each product is different and is included in the description of that product (if the items mentioned in this section are not included, there is no guarantee for that product) Now if the product is purchased If it has a warranty and has a technical defect, the buyer must provide the goods and receive after-sales services from the warranty company in accordance with the conditions stated in the warranty card.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Rules;
