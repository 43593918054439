import React, { useState, useEffect } from "react";
import "./Warranty.scss";

import { getProductCountBasket } from "../../defz.js";

import warrantyBanner from "../../assets/images/warrantyBanner.png";
import Garanty from "../../assets/svg/garanty.svg";
import Payment from "../../assets/svg/Payment.svg";
import Support from "../../assets/svg/Support.svg";
import Delivery from "../../assets/svg/Delivery.svg";

import $ from "jquery";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function Guranty() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-Warranty">
        <img
          src={warrantyBanner}
          alt="warrantyBanner"
          className="warranty-banner"
        />
        <div className="warrantycard">
          <form action="">
            <label htmlFor="">Warranty code</label>
            <div>
              <input placeholder="Enter your warranty code" type="text" />
              <button>Confirmation</button>
            </div>
          </form>
        </div>
        <div className="support">
          <img src={Delivery} alt="" />
          <img src={Support} alt="" />
          <img src={Payment} alt="" className="payment-icon" />
          <img src={Garanty} alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Guranty;
