import React from "react";

import { TileLayer, MapContainer, Marker, Popup } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";

import "leaflet/dist/leaflet.css";

function Index() {
  const position = [38.03753, 46.14737];

  const iconMarkup = renderToStaticMarkup(
    <i className="pin1" />
  );
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });

  return (
    <>
      <MapContainer
        center={position}
        zoom={13}
        scrollWheelZoom={false}
        style={{
          
          height: "25vh",
          width: "100%",
          borderRadius: "10px",
          border: "1px dashed gray",
          margin: " 20px auto",
        }}
      >
        <TileLayer
          attribution='&copy; <a to="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={customMarkerIcon}>
          <Popup>RabetEttehad</Popup>
        </Marker>
      </MapContainer>
    </>
  );
}

export default Index;
