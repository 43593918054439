import React, { useState } from "react";

import signIn from "../../assets/images/signIn.png";

import $ from "jquery";

import { fetchApi } from "../../defz";

import { setUserToken } from "../../redux/Auth/userActions";
import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleNotch);

function SubmitCode({ setUserToken, statuCodeHandler }) {
  const [code, setCode] = useState();

  const submitHandler = () => {
    $(".buttonText").css({ display: "none" });
    $(".buttonSpin").css({ display: "flex" });

    fetchApi(
      `/api/user/signin/phone_number=${localStorage.getItem(
        "phoneNumber"
      )}&v_code=${code}`,
      ""
    ).then((res) => {

      if (res.description === "Invalid verification!") {
        $("#code").css({ border: "1px solid red" });
        $(".buttonText").css({ display: "flex" });
        $(".buttonSpin").css({ display: "none" });
      } else if (res.des === "Signed in successfully!") {
        setUserToken(res.user_token);
        statuCodeHandler("set token");
      } else if (localStorage.getItem("register") === "false") {
        $(".buttonText").css({ display: "flex" });
        $(".buttonSpin").css({ display: "none" });

        $("#code").css({ border: "1px solid #9e9e9e" });
        fetchApi(`/api/user/signup`, "", {
          name: " ",
          phone_number: localStorage.getItem("phoneNumber"),
          v_code: code,
        }).then((res) => {
          if (res.description === "User signed up successfully!") {
            fetchApi(
              `/api/user/signin/phone_number=${localStorage.getItem(
                "phoneNumber"
              )}&v_code=${code}`,
              ""
            ).then((res) => {
              if (res.des === "Signed in successfully!") {
                setUserToken(res.user_token);
                statuCodeHandler("set token");
              }
            });
          }
        });
      }
    });
  };

  return (
    <>
      <div className="forgetPassword">
        <section className="signInPic">
          <img src={signIn} alt="signIn" />
        </section>
        <section className="content">
          <div className="title">وارد کردن کد</div>
          <input
            type="text"
            id="code"
            onChange={(e) => setCode(e.target.value)}
            placeholder="کد ارسال شده را وارد کنید"
          />
          <button onClick={submitHandler}>
            <span className="buttonText">ارسال</span>
            <span className="buttonSpin">
              <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
            </span>
          </button>
          <a href="/#">وارد کردن شماره موبایل</a>
        </section>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});
const mapDispatchToProps = (dispatch) => ({
  setUserToken: (token) => dispatch(setUserToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitCode);
