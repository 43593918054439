import moment from 'jalali-moment';
import $ from "jquery";

export const mainAddress = `https://rabet.iran.liara.run/`;

export const hostName = "https://elpino-pwa.herokuapp.com";

export const fetchApi = async (url, token, body) => {
  let lastResult;
  try {
    if (body) {
      await fetch(`${mainAddress}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth-Token': token
        },
        body: JSON.stringify(body),
      })
        .then(respone => respone.json())
        .then(result => lastResult = result)
        .catch(err => lastResult = err);
    } else {
      await fetch(`${mainAddress}/${url}`, {
        method: 'GET',
        headers: {
          'Auth-Token': token
        },
      })
        .then(respone => respone.json())
        .then(result => { lastResult = result })
        .catch(err => lastResult = err);
    }

  } catch (error) {
    console.log(error);
    lastResult = error;
  };
  return lastResult;
};

export const sliceNumber = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const timeDetector = (date) => {
  let result = '';
  const date1 = date;
  const date2 = moment().locale('fa').format('YYYY-MM-DD HH:mm:ss');
  let diffrenceDays = moment(date2).diff(date1, 'days');
  let diffrenceHours = moment(date2).diff(date1, 'hours');
  let diffrenceMinutes = moment(date2).diff(date1, 'minutes');

  if (diffrenceDays > 0) {
    result = `${diffrenceDays} روز پیش`
  } else if (diffrenceHours > 0) {
    result = `${diffrenceHours} ساعت پیش`
  } else if (diffrenceMinutes === 0) {
    result = `لحظاتی پیش`
  } else if (diffrenceMinutes > 0) {
    result = `${diffrenceMinutes} دقیقه پیش`
  }
  return result;
}

export const installmentTimeDetector = (date) => {
  const date1 = date;
  const date2 = moment().locale('fa').format('YYYY-MM-DD');
  return moment(date1).diff(date2, 'days');
}

export const priceDivision = (number, place) => {
  number += "";
  number = number.replace(",", "");
  let x = number.split(".");
  let y = x[0];
  let z = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(y)) y = y.replace(rgx, "$1" + "," + "$2");
  $(place).html(y + z + " تومان ");
  return y + z;
};

export const getProductCountBasket = () => {
  let basket = localStorage.getItem("basket");
  if (basket === null) {
    return 0;
  } else {
    let count = 0;
    basket = JSON.parse(basket);

    basket.forEach(item => {
      count += item.count;
    })
    return count;
  }
}

export const Provinces = [
  {
      "_id": "5edf407224ae4600207ec9b8",
      "title": "آذربایجان شرقی "
  },
  {
      "_id": "5edf409124ae4600207ec9ba",
      "title": "آذربایجان غربی "
  },
  {
      "_id": "5edf40a924ae4600207ec9bc",
      "title": "اردبیل"
  },
  {
      "_id": "5edf40c424ae4600207ec9bd",
      "title": "اصفهان"
  },
  {
      "_id": "5edf40cd24ae4600207ec9be",
      "title": "البرز"
  },
  {
      "_id": "5edf40da24ae4600207ec9bf",
      "title": "ایلام"
  },
  {
      "_id": "5edf40e224ae4600207ec9c0",
      "title": "بوشهر"
  },
  {
      "_id": "5edf40fc24ae4600207ec9c1",
      "title": "تهران"
  },
  {
      "_id": "5edf412924ae4600207ec9c2",
      "title": "چهارمحال و بختیاری "
  },
  {
      "_id": "5edf413424ae4600207ec9c3",
      "title": "خراسان جنوبی "
  },
  {
      "_id": "5edf414c24ae4600207ec9c4",
      "title": "خراسان رضوی "
  },
  {
      "_id": "5edf415f24ae4600207ec9c5",
      "title": "خراسان شمالی "
  },
  {
      "_id": "5edf417224ae4600207ec9c6",
      "title": "خوزستان"
  },
  {
      "_id": "5edf418024ae4600207ec9c7",
      "title": "زنجان"
  },
  {
      "_id": "5edf418724ae4600207ec9c8",
      "title": "سمنان"
  },
  {
      "_id": "5edf419a24ae4600207ec9c9",
      "title": "سیستان و بلوچستان"
  },
  {
      "_id": "5edf41bd24ae4600207ec9ca",
      "title": "فارس"
  },
  {
      "_id": "5edf41c424ae4600207ec9cb",
      "title": "قزوین"
  },
  {
      "_id": "5edf41ca24ae4600207ec9cc",
      "title": "قم"
  },
  {
      "_id": "5edf41da24ae4600207ec9cd",
      "title": "کردستان"
  },
  {
      "_id": "5edf41e024ae4600207ec9ce",
      "title": "کرمان"
  },
  {
      "_id": "5edf41ea24ae4600207ec9cf",
      "title": "کرمانشاه"
  },
  {
      "_id": "5edf420224ae4600207ec9d0",
      "title": "کهگیلویه و بویراحمد "
  },
  {
      "_id": "5edf420924ae4600207ec9d1",
      "title": "گلستان"
  },
  {
      "_id": "5edf421724ae4600207ec9d2",
      "title": "گیلان"
  },
  {
      "_id": "5edf422a24ae4600207ec9d3",
      "title": "لرستان"
  },
  {
      "_id": "5edf423024ae4600207ec9d4",
      "title": "مازندران"
  },
  {
      "_id": "5edf423824ae4600207ec9d5",
      "title": "مرکزی"
  },
  {
      "_id": "5edf424d24ae4600207ec9d6",
      "title": "هرمزگان"
  },
  {
      "_id": "5edf425724ae4600207ec9d7",
      "title": "همدان"
  },
  {
      "_id": "5edf426624ae4600207ec9d8",
      "title": "یزد"
  }
];