import React, { useEffect, useState } from "react";
import "./ProductCard.scss";

import { Link } from "react-router-dom";

import Star from "../../assets/images/star.png";

import { sliceNumber, fetchApi } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

function ProductCard({
  id,
  name,
  pic,
  point,
  percent,
  price,
  offPrice,
  agentPrice,
  agentOffPrice,
  token,
}) {
  const [userType, setUserType] = useState("");

  useEffect(() => {
    fetchApi(`/api/user/validate`, token, "").then((res) => {
      setUserType(res.type);
    });
  }, [token]);

  return (
    <div className="product-card">
      <Link to={`/product/${id}`}>
        <div
          className="picture"
          style={{ backgroundImage: `url(${pic})` }}
        ></div>
        <p className="title">{name}</p>
        <div className="off">
          <div className="right">
            <p>{point}</p>
            <img src={Star} alt="" />
          </div>
        </div>
      </Link>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
