import React, { useState } from "react";
import "./Footer.scss";

import { ToastContainer, toast } from "react-toastify";

import downloadImg from "../../assets/images/sibApp.png";

import $ from "jquery";

import Button from "../Button/Button";

import { fetchApi } from "../../defz";

import { Link } from "react-router-dom";

function Footer() {
  const [email, setEmail] = useState("");

  return (
    <footer>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="top" />
      <div className="content">
        <div className="head">
        <div className="left">
            <p className="title">Membership</p>
            <p className="tip">
            Subscribe to the newsletter to be informed of the latest news and products of the site
            </p>

            <form>
            <Button
                type="submit"
                title="Membership"
                onClickAction={(e) => {
                  e.preventDefault();
                  if (email.length >= 7 && email.indexOf("@") > 0) {
                    $("#emailInput").css({ border: "1px solid #f5f5f5" });
                    fetchApi(`api/user/submit/Newsletters`, "", {
                      email: email,
                    }).then((res) => {
                      if (res.description === "done!") {
                        toast.success("successfully joined", {
                          position: "bottom-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      } else if (res.status_code === 402) {
                        toast.error(res.description_fa, {
                          position: "bottom-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                  } else {
                    $("#emailInput").css({ border: "1px solid red" });
                  }
                }}
              />
              <input
                id="emailInput"
                type="email"
                placeholder="Enter Your Email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              
            </form>
          </div>
          <div className="right">
            <section>
              <p className="title">Main links</p>
              <Link to="/">Main Page</Link>
              <Link to="/contactus">Support</Link>
              <Link to="/aboutUs"> About</Link>
            </section>
            <section>
              <p className="title">customer services</p>
              <Link to="/instagram">Lottery</Link>
              <Link to="/warranty">Warranty tracking</Link>
              <a
                href="https://blog.rabetettehad.ir/"
                target={"_blank"}
                rel="noreferrer"
              >
                Blog
              </a>
            </section>
            <section>
              <p className="title">Guide</p>
              <Link to="/">How to place an order</Link>
              <Link to="/rule">Terms and Conditions</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </section>
          </div>

        </div>
        <div className="banner">

          <div className="left">
            <a rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.rabetettehad&gl=GB" target={"_blank"} className="link google-play"></a>
            <a
              rel="noreferrer"
              href="https://cafebazaar.ir/app/com.rabetettehad"
              className="link bazzar"
              target={"_blank"}
            ></a>
            <a
              rel="noreferrer"
              href="https://rabetettehad.ir/download/rabet-ettehad.apk"
              target={"_blank"}
            >
              <img
                src={downloadImg}
                alt="downloadImg"
                className="downloadImg"
              />
            </a>
          </div>
          <div className="right">
            <p className="title">Download Etihad interface application</p>
            <div className="logo" />

          </div>
        </div>
        <div className="info">
          <div className="right">
            <p className="title">RabetEttegad</p>
            <p className="description">
            Etihad interface as one of the oldest online stores with more than a decade of experience, by adhering to three principles, payment on the spot, 7-day money back guarantee and guarantee the authenticity of the goods, has succeeded in becoming the largest online store in the world. Become Iran. As soon as you enter the Etihad interface site, you will face a world of goods! You will find everything you need and that comes to mind here
            </p>
          </div>
          <div className="left">
          </div>
        </div>
        <div className="copy-right">
          <div>
          Use of the contents of the Etihad interface online store is for non-commercial purposes only and without mentioning the source. Designed and developed by{" "}
            <a target="_blank" href="https://platinco.ir">
              Platin
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
