import React, { useState, useEffect } from "react";
import "./MobileMenu.scss";

import { Link } from "react-router-dom";

import $ from "jquery";

import logo from "../../assets/svg/logo.svg";
import down from "../../assets/svg/sort-down.svg";

import { fetchApi } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

function MobileMenu({ token }) {
  const [cat, setCat] = useState("");

  useEffect(() => {
    fetchApi("en/api/admin/shop/fetch_cat", "", "").then((res) => {
      setCat(res.data);
    });
  }, [token]);

  const productHandler = (e) => {
    e.preventDefault();
    $(".secound-list").slideToggle();
    $(".product-title").toggleClass("titleColor");
    $(".down-product").toggleClass("rotate");
  };

  const aboutHandler = (e) => {
    e.preventDefault();
    $(".third-list").slideToggle();
    $(".about-title").toggleClass("titleColor");
    $(".down-about").toggleClass("rotate");
  };

  return (
    <>
      <div className="menuMobile">
        <img src={logo} alt="logo" />
        <ul className="menu-list">
          <li className="menu-item">
            <Link to="/" className="menu-link">
              Home
            </Link>
          </li>
          <hr />
          <li className="menu-item">
            <a
              href="/#"
              className="menu-link product-title"
              onClick={productHandler}
            >
              Products
              <img src={down} alt="down" className="down-product" />
            </a>

            <ul className="secound-list">
              {cat
                ? cat.map((category, index) => {
                    return (
                      <li className="third-item" key={index}>
                        <Link to={`/filter/${category._id}`}>
                          {category.title}
                        </Link>
                      </li>
                    );
                  })
                : null}
            </ul>
          </li>
          <hr />
          <li className="menu-item">
            <a
              href="/#"
              className="menu-link about-title"
              onClick={aboutHandler}
            >
                RabetEttehad
              <img src={down} alt="down" className="down-about" />
            </a>
            <ul className="third-list">
              <li className="third-item">
                <Link to="/agent">RabetEttehad</Link>
              </li>
              <li className="third-item">
                <Link to="/aboutUs">About Us</Link>
              </li>
              <li className="third-item">
                <Link to="/contactUs">Contact Us</Link>
              </li>
            </ul>
          </li>
          <hr />
          <li className="menu-item">
            <a href="/#" className="menu-link">
            Tutorials & announcements
                          <img src="" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
