import userAction from './userTypes';

const INITIAL_STATE = {
  currentUser: null,
  token: null
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userAction.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      }
    default:
      return state;
  }
}

export default userReducer;