import React, { useState, useEffect } from "react";
import "./Product.scss";

import { useParams, Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import $ from "jquery";

import { Rating } from "react-simple-star-rating";

import productPic from "../../assets/images/product-pic.png";
import logo from "../../assets/svg/logo.svg";
import Garanty from "../../assets/svg/garanty.svg";
import Payment from "../../assets/svg/Payment.svg";
import Support from "../../assets/svg/Support.svg";
import Delivery from "../../assets/svg/Delivery.svg";

import { sliceNumber, fetchApi, getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";
import Button from "../../components/Button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import Fancybox from "../../components/Fancybox/Fancybox";

function Product({ token }) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [basketCount, setBasketCount] = useState(0);
  const [userType, setUserType] = useState("");
  const [img, setImg] = useState("");

  const [product, setProduct] = useState("");
  const [star, setStar] = useState(100);

  const [starRate, setStarRate] = useState(100);
  const [state, setState] = useState("noComment");
  const [openion, setOpenion] = useState("");
  const [cats, setCats] = useState("");

  const { id } = useParams();

  useEffect(() => {
    fetchApi(`/en/api/user/shop/fetch_one`, "", {
      id,
    }).then((res) => {
      setCats(res.data[0].cats)
      setProduct(res.data[0]);
      setImg(res.data[0].pics[0]);
      setStar(res.data[0].scoore);
    });

    fetchApi(`/api/user/validate`, token, "").then((res) => {
      setUserType(res.type);
    });

    fetchApi(`/en/api/user/shop/fetch_one_product_comments`, "", {
      productid: id,
    }).then((res) => {
      setOpenion(res.data);
    });

    setBasketCount(getProductCountBasket());


  }, [id , token]);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const forgetPasswordHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(false);
    setPassword(true);
  };

  // const [rating, setRating] = useState(0);
  $(".comments").css({ display: "block" });
  const commentHandler = (e) => {
      if (e.target.id === "comment") {
      $(".comments").css({ display: "block" });
      $(".payWay").css({ display: "none" });
      $("#comment").addClass("active");
      $("#pay").removeClass("active");
    } else if (e.target.id === "pay") {
      $(".comments").css({ display: "none" });
      $(".payWay").css({ display: "block" });
      $("#comment").removeClass("active");
      $("#pay").addClass("active");
    }
  };

  const rejectCommend = (e) => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
  };
  const commentbody = {
    productid : id , 
    title : $('#openion').val(),
    dis : $('#message').val(),
    score : starRate , 
    state : state,
  }
 
  const addCommentHandler = (e) => {
    $(".cover").css({ display: "flex" });
    $(".submitComment").css({ display: "block" });
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  //get basket
  let basket = localStorage.getItem("basket");

  //if not exist basket or exist in localstorage
  if (basket === null) {
    basket = [];
  } else {
    basket = JSON.parse(basket);
  }

  //add basket button handler
  const addToBasket = (e) => {
    //basket item variable
    let itemBasket = {};

    //get product id
    let id = e.target.id;

    //check index product varable
    let indexProduct;

    //get index product in localstorage
    basket.forEach((item, index) => {
      item.id === id ? (indexProduct = index) : (indexProduct = false);
    });
    //upper count product or add product to list
    if (indexProduct !== false && indexProduct !== undefined) {
      let count = basket[indexProduct].count;
      basket[indexProduct].count = count + 1;
      toast.success("به سبد خرید افزوده شد", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (id.length > 0) {
        itemBasket = { id: id, count: 1 };
        basket.push(itemBasket);
        toast.success("به سبد خرید افزوده شد", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    $(".payButton").css({ backgroundColor: "green" });

    setTimeout(() => {
      $(".payButton").css({ backgroundColor: "#f6931e" });
    }, 3000);
    //save in localstorage
    localStorage.setItem("basket", JSON.stringify(basket));
    setBasketCount(getProductCountBasket());
  };

  const validationSchema = yup
    .object({
      title: yup
        .string()
        .min(3, "At least 3 characters")
        .max(20, "At least 20 characters")
        .required("The name field is empty"),
      message: yup
        .string()
        .min(10, "At least 10 characters")
        .max(50, "At least 50 characters")
        .required("The name field is empty"),
      description: yup.string().default(),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleRating = (rate) => {
    setStarRate(rate);
  };

  const suggestHandler = (e) => {
    setState(e.target.id);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    fetchApi(`/en/api/user/shop/send_comment_product`, token, {
      score: starRate,
      title: data.title,
      dis: data.message,
      state,
      productid: product._id,
    }).then((res) => {
      if (res.description === "done!") {
        $(".cover").css({ display: "none" });
        $(".submitComment").css({ display: "none" });
        toast.success("Your comment has been submitted", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="cover" onClick={rejectCoverHandler}></div>
      <form className="submitComment" onSubmit={handleSubmit(onSubmit)}>
        <section className="title">Add a comment</section>
        <section className="point">
          <span>Your rating on this product</span>
          <span>
            <Rating
              initialValue={5}
              size={20}
              showTooltip={false}
              onClick={handleRating}
            />
          </span>
        </section>
        <hr />
        <section className="openion">
          <label htmlFor="openion">Comment Title (Required)</label>
          <input
            id="openion"
            type="text"
            name="name"
            className={`${errors.title ? "is-invalid" : ""}`}
            {...register("title")}
          />
          <small className="allert">{errors.title?.message}</small>
        </section>
        <section className="openion">
          <label htmlFor="message">Comment text (mandatory)</label>
          <textarea
            name=""
            id="message"
            cols="20"
            rows="5"
            className={`${errors.message ? "is-invalid" : ""}`}
            {...register("message")}
          ></textarea>
          <small className="allert">{errors.message?.message}</small>
        </section>
        <section className="suggest" onClick={suggestHandler}>
          <span>Buy this product</span>
          <span>
            <input type="radio" name="suggest" id="suggest" />
            I suggest
          </span>
          <span>
            <input type="radio" name="suggest" id="noSuggest" />
            I do not recommend
          </span>
          <span>
            <input type="radio" name="suggest" id="noComment" defaultChecked />
            No idea
          </span>
        </section>
        <section className="rule">
          <input type="radio" name="" id="" defaultChecked />
          <Link to="/rule">
          I accept the terms and conditions of the RabetEttehad communication site for comment
          </Link>
        </section>
        <section className="buttons">
          <button>Add a new comment</button>
          <button onClick={rejectCommend}>Cancel</button>
        </section>
      </form>

      {password ? <ForgetPassword signInHandler={signInHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}

      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={basketCount}
      />
      <main className="index-product">
        <div className="map">Home / Digital Goods / {cats}</div>
        <div className="product">
        <div className="img1">
            <Fancybox>
              <a data-fancybox="gallery" href={img}>
                <img src={product ? img : productPic} alt="productPic" />
              </a>
            </Fancybox>
          </div>
          <div className="product thumbnails-mob">
          <div className="thumbnails">
            <Fancybox>
              <p>
                {product
                  ? product.pics.map((pic, index) => {
                      return (
                        <a data-fancybox="gallery" href={pic} key={index}>
                          <img src={pic} alt={index} />
                        </a>
                      );
                    })
                  : null}
              </p>
            </Fancybox>
          </div>

        </div>
          <div className="content">
            <span className="title">
              {product ? product.title : "Product title"}
            </span>
            <span className="subTitle">{product.subtitle}</span>
            <hr />
            <span>
            <Rating
              initialValue={5}
              size={20}
              showTooltip={false}
              onClick={handleRating}
            />
          </span>
            <div className="detailss">{product.details}</div>
   
            <div className="product-contact">

            <Link to="/contactus">
                    <button className="shetbtn">Contact Us</button>
                  </Link>
            </div>
          </div>
          <div className="img">
            <Fancybox>
              <a data-fancybox="gallery" href={img}>
                <img src={product ? img : productPic} alt="productPic" />
              </a>
            </Fancybox>
          </div>
                <Link to="/contactus">
                    <button className="shetbtn1">Contact Us</button>
                  </Link>
        </div>

        <div className="product thumbnails-of">
          <div className="thumbnails">
            <Fancybox>
              <p>
                {product
                  ? product.pics.map((pic, index) => {
                      return (
                        <a data-fancybox="gallery" href={pic} key={index}>
                          <img src={pic} alt={index} />
                        </a>
                      );
                    })
                  : null}
              </p>
            </Fancybox>
          </div>

        </div>

        <div className="comment">
          <div className="labels" onClick={commentHandler}>
            <section className="label active" id="comment">
            Comments
            </section>
            <section className="label labelbtn" id="pay">
            payment method
            </section>
          </div>
          <div className="comments">
            <section className="add-comment">
              <div className="content">
                <span>How can I comment on this product?</span>
                <span>
                If you have purchased this product from the Alliance interface, you can leave a comment by clicking the button
                </span>
              </div>
              <button onClick={addCommentHandler}>Add Comment</button>
            </section>
            <hr />
            {openion
              ? openion.map((openion, index) => {
                  return (
                    <section className="comment" key={index}>
                      <div className="row">
                        <div className="content">
                          <span>
                            <Rating
                              initialValue={openion.score}
                              size={20}
                              showTooltip={false}
                            />
                          </span>
                          <span>{openion.coment}</span>
                        </div>
                        {openion.state === "suggest" ? (
                          <div className="status-success">
                            I recommend buying this product                          
                            </div>
                        ) : (
                          ""
                        )}
                        {openion.state === "noSuggest" ? (
                          <div className="status-danger">
                              I do not recommend buying this product
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">{openion.dis}</div>
                      <hr />
                    </section>
                  );
                })
              : null}
          </div>
          <div className="payWay">
            <b>Payment on the spot</b>
            <br />
            Ability to choose the payment method on the spot, taking into account the terms of the order, to
            The smart face will be checked by the system and if it is possible to choose this method, you can pay the amount of your order upon delivery of the goods, with all bank cards through the card reader. Also, if your order has the following conditions, it will not be possible to pay on the spot.
            <br />
            <br />
            <b>Internet payment</b>
            <br />
            Alliance interface users can register an order through the portal
            Online, pay for your order online. Successful payment of the fee is the final registration of this payment for the order, and then a text message is sent to the user stating the successful registration of the order, and there is no need to notify the payment of the order, and the order automatically enters the preparation and sending stages.
          </div>
          
        </div>
        <div className="support">
            <img src={Delivery} alt="" />
            <img src={Support} alt="" />
            <img src={Payment} alt="" className="payment-icon" />
            <img src={Garanty} alt="" />
          </div>
      </main> 
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
