import React, { useState, useEffect } from "react";
import "./Profile.scss";

import { useNavigate } from "react-router-dom";

import $ from "jquery";

import { sliceNumber, getProductCountBasket } from "../../defz";

import { FaUserCircle } from "react-icons/fa";
import { BsBasket2Fill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { HiDocumentReport } from "react-icons/hi";
import { IoMdInformationCircle } from "react-icons/io";
import { BiExit } from "react-icons/bi";

import deleteIcon from "../../assets/images/delete.png";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import { fetchApi, Provinces } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleNotch);

function Profile({ token }) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [profile, setProfile] = useState("");

  const [name, setName] = useState("");
  const [meliCode, setMeliCode] = useState("");
  const [email, setEmail] = useState("");

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [cityName, setCityName] = useState("");
  const [addressProfile, setAddressProfile] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState([]);
  const [index, setIndex] = useState();
  const [order, setOrder] = useState([]);

  const history = useNavigate();

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
    $(".submitComment").css({ display: "none" });
  };

  const rejectCommend = (e) => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
  };

  const addCommentHandler = (e) => {
    $(".cover").css({ display: "flex" });
    $(".submitComment").css({ display: "block" });
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  useEffect(() => {
    fetchApi(`api/user/fetchMyAccount`, token, "").then((res) => {
      console.log(res);
      if (res.description === "Access denied!") {
        history("/");
      }
      setProfile(res);
      setAddresses(res.adress);
      setNewAddress(res.adress);
    });

    fetchApi(`en/api/user/shop/fetch_my_order`, token, "").then((res) => {
      setOrder(res.data);
    });
  }, [token]);

  const menuHandler = (e) => {
    e.preventDefault();

    if (e.target.id === "orders") {
      $("#profile").css({ display: "none" });
      $("#follow").css({ display: "none" });
      $("#follows").css({ color: "#7e7676" });
      $("#order").css({ display: "block" });
      $("#orders").css({ color: "#6A6CA0" });
      $("#address").css({ display: "none" });
      $("#addresses").css({ color: "#7e7676" });
      $("#information").css({ display: "none" });
      $("#myProfile").css({ color: "#7e7676" });
      $("#first").css({ display: "none" });
    } else if (e.target.id === "addresses") {
      $("#profile").css({ display: "none" });
      $("#follow").css({ display: "none" });
      $("#follows").css({ color: "#7e7676" });
      $("#order").css({ display: "none" });
      $("#orders").css({ color: "#7e7676" });
      $("#address").css({ display: "block" });
      $("#addresses").css({ color: "#6A6CA0" });
      $("#information").css({ display: "none" });
      $("#myProfile").css({ color: "#7e7676" });
      $("#first").css({ display: "none" });
    } else if (e.target.id === "follows") {
      $("#profile").css({ display: "none" });
      $("#follow").css({ display: "block" });
      $("#follows").css({ color: "#6A6CA0" });
      $("#order").css({ display: "none" });
      $("#orders").css({ color: "#7e7676" });
      $("#address").css({ display: "none" });
      $("#addresses").css({ color: "#7e7676" });
      $("#information").css({ display: "none" });
      $("#myProfile").css({ color: "#7e7676" });
      $("#first").css({ display: "none" });
    } else if (e.target.id === "myProfile") {
      $("#profile").css({ display: "none" });
      $("#follow").css({ display: "none" });
      $("#follows").css({ color: "#7e7676" });
      $("#order").css({ display: "none" });
      $("#orders").css({ color: "#7e7676" });
      $("#address").css({ display: "none" });
      $("#addresses").css({ color: "#7e7676" });
      $("#information").css({ display: "block" });
      $("#myProfile").css({ color: "#6A6CA0" });
      $("#first").css({ display: "none" });
    }
  };

  const editProfileHandler = (e) => {
    e.preventDefault();
    $("#profile").css({ display: "none" });
    $("#follow").css({ display: "none" });
    $("#follows").css({ color: "#7e7676" });
    $("#order").css({ display: "none" });
    $("#orders").css({ color: "#7e7676" });
    $("#address").css({ display: "none" });
    $("#addresses").css({ color: "#7e7676" });
    $("#information").css({ display: "block" });
    $("#myProfile").css({ color: "#6A6CA0" });
    $("#first").css({ display: "none" });
  };

  const updateProfileHandler = () => {
    if (name.length > 4) {
      $("#nameProfile").css({ border: "1px solid #d6d6d6" });
    } else {
      $("#nameProfile").css({ border: "1px solid red" });
    }

    if (email.length > 4) {
      $("#emailProfile").css({ border: "1px solid #d6d6d6" });
    } else {
      $("#emailProfile").css({ border: "1px solid red" });
    }

    if (meliCode.length === 10) {
      $("#meliCodeProfile").css({ border: "1px solid #d6d6d6" });
    } else {
      $("#meliCodeProfile").css({ border: "1px solid red" });
    }

    if (name.length > 4 && email.length > 4 && meliCode.length === 10) {
      $("#buttonTextAgent").css({ display: "none" });
      $("#buttonSpinAgent").css({ display: "block" });

      fetchApi(`/api/user/updateprofile`, token, {
        name,
        email,
        id_code: meliCode,
      }).then((res) => {
        if (res.description === "done!") {
          toast.success("پروفایل با موفقیت آپدیت شد", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $("#updateProfileSubmit").css({ display: "none" });
        }
      });
    }
  };

  useEffect(() => {
    if (addresses.length !== newAddress.length) {
      fetchApi(`/api/user/address/add`, token, {
        address: addresses,
      }).then((res) => {
        if (res.description === "done!") {
          $(".submitComment").css({ display: "none" });
          $(".cover").css({ display: "none" });
        }
      });
    }
  }, [newAddress, addresses, token]);

  const addAddressHanlder = () => {
    if (state.length > 0) {
      $("#state").css({ border: "1px solid #d6d6d6" });
    } else {
      $("#state").css({ border: "1px solid red" });
    }

    if (cityName.length > 0) {
      $("#city").css({ border: "1px solid #d6d6d6" });
    } else {
      $("#city").css({ border: "1px solid red" });
    }

    if (addressProfile.length > 10) {
      $("#addressProfile").css({ border: "1px solid #d6d6d6" });
    } else {
      $("#addressProfile").css({ border: "1px solid red" });
    }

    if (postalCode.length === 10) {
      $("#postalCode").css({ border: "1px solid #d6d6d6" });
    } else {
      $("#postalCode").css({ border: "1px solid red" });
    }

    if (
      state.length > 0 &&
      cityName.length > 0 &&
      addressProfile.length > 10 &&
      postalCode.length === 10
    ) {
      let userAddress = {
        address:
          state + "/" + cityName + "/" + addressProfile + "/" + postalCode,
      };
      setAddresses((prevAddress) => [...prevAddress, userAddress]);

      setNewAddress(userAddress);
    }
  };

  const addressHandler = (e) => {
    setIndex(e.target.id);

    if (index.length > 0) {
      $(".cover").css({ display: "block" });
      $(".deleteForm").css({ display: "block" });
    }
  };

  const rejectDeleteAddress = (e) => {
    $(".cover").css({ display: "none" });
    $(".deleteForm").css({ display: "none" });
  };

  const deleteAddressHanlder = (e) => {
    fetchApi(`/api/user/address/del`, token, {
      index,
    }).then((res) => {
      if (res.description === "done!") {
        $(".cover").css({ display: "none" });
        $(".deleteForm").css({ display: "none" });

        fetchApi(`api/user/fetchMyAccount`, token, "").then((res) => {
          setProfile(res);
          setAddresses(res.adress);
          setNewAddress(res.adress);
        });
      }
    });
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  const stateHanlder = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let option = optionElement.getAttribute("data-id");

    setCity([]);

    fetchApi(`/api/mid/fetch/city//id=${option}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((respone) => setCity(respone.data));
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="cover" onClick={rejectCoverHandler}></div>
      <div className="deleteForm">
        <section className="title">حذف آدرس</section>
        <section className="openion">
          <label htmlFor="state">آیا از حذف آدرس مورد نظر مطمئن هستید؟</label>
        </section>
        <section className="buttons">
          <button onClick={deleteAddressHanlder}>بله</button>
          <button onClick={rejectDeleteAddress}>انصراف</button>
        </section>
      </div>
      <div className="submitComment" id="addAddressFrom">
        <section className="title">افزودن آدرس</section>
        <section className="openion">
          <label htmlFor="state">استان</label>
          <select
            name=""
            id="state"
            onChange={(e) => {
              setState(e.target.value);
              stateHanlder(e);
            }}
          >
            <option value="">استان</option>
            {Provinces
              ? Provinces.map((state, index) => {
                  return (
                    <option value={state.title} data-id={state._id} key={index}>
                      {state.title}
                    </option>
                  );
                })
              : null}
          </select>
        </section>
        <section className="openion">
          <label htmlFor="city">شهر</label>
          <select
            name=""
            id="city"
            onChange={(e) => {
              setCityName(e.target.value);
            }}
          >
            <option value="">شهر</option>
            {city
              ? city.map((city, index) => {
                  return (
                    <option value={city.title} data-id={city._id} key={index}>
                      {city.title}
                    </option>
                  );
                })
              : null}
          </select>
        </section>
        <section className="openion">
          <label htmlFor="openion">ادامه آدرس</label>
          <input
            type="text"
            name=""
            id="addressProfile"
            onChange={(e) => setAddressProfile(e.target.value)}
          />
        </section>
        <section className="openion">
          <label htmlFor="postacode">کد پستی</label>
          <input
            type="number"
            name=""
            id="postalCode"
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </section>
        <section className="buttons">
          <button onClick={addAddressHanlder}>افزودن آدرس جدید</button>
          <button onClick={rejectCommend}>انصراف</button>
        </section>
      </div>
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-profile">
        <aside className="aside">
          <div className="menu" onClick={menuHandler}>
            <div>
              <section className="name">
                <FaUserCircle />
                <div>
                  <h3>{profile.name}</h3>
                  <h4>{profile.phoneNumber}</h4>
                </div>
              </section>
              <hr />
              <a href="/#">
                <section className="item" id="orders">
                  <BsBasket2Fill id="ordersIcon" /> خرید های انجام شده
                </section>
              </a>
              <a href="/#">
                <section className="item" id="addresses">
                  <MdLocationPin /> آدرس ها
                </section>
              </a>
              <a href="/#">
                <section className="item" id="follows">
                  <HiDocumentReport /> پیگیری سفارشات
                </section>
              </a>
              <a href="/#">
                <section className="item" id="myProfile">
                  <IoMdInformationCircle /> اطلاعات حساب
                </section>
              </a>
            </div>
            <div>
              <hr />
              <a
                href="/#"
                className="item"
                id="exit"
                onClick={(e) => {
                  localStorage.clear();
                  history("/");
                }}
              >
                <BiExit />
                خروج
              </a>
            </div>
          </div>
        </aside>

        <div className="information" id="first">
          <div className="title">اطلاعات شخصی</div>
          <div className="card">
            <div className="row">
              <div className="col">
                <label htmlFor="">نام و نام خانوادگی</label>
                <input type="text" value={profile.name} disabled />
              </div>
              <div className="col">
                <label htmlFor="">پست الکترونیکی</label>
                <input type="text" value={profile.email} disabled />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="">شماره تلفن</label>
                <input type="text" value={profile.phoneNumber} disabled />
              </div>
              <div className="col">
                <label htmlFor="">کد ملی</label>
                <input type="number" value={profile.id_code} disabled />
              </div>
            </div>
            <div className="row">
              <div className="col-100">
                <a href="/#" onClick={editProfileHandler}>
                  جهت ویرایش کلیک کنید
                </a>
              </div>
            </div>
          </div>

          <div className="title">آخرین سفارش ها</div>
          <div className="card">
            <div className="order">
              <table>
                <thead>
                  <tr>
                    <td>شماره سفارش</td>
                    <td>قیمت کل</td>
                    <td>تعداد</td>
                    <td>وضعیت</td>
                  </tr>
                </thead>
                <tbody>
                  {order
                    ? order.map((item, index) => {
                        let count = 0;
                        item.product_id_list.map((product) => {
                          count += product.count;
                        });

                        return (
                          <tr className="order-item" key={index}>
                            <td>{item.localid}</td>
                            <td className="price">
                              {sliceNumber(item.total_mony)} تومان
                            </td>
                            <td className="count">{count}</td>
                            <td className="status">
                              {item.payed === true
                                ? "پرداخت موفق"
                                : "پرداخت ناموفق"}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="information" id="follow">
          <div className="title">پیگیری سفارشات</div>
          <div className="card">
            <div className="order">
              <table>
                <tr>
                  <th>شماره سفارش</th>
                  <th>قیمت کل</th>
                  <th>تعداد</th>
                  <th>وضعیت</th>
                </tr>
                {order
                  ? order.map((item, index) => {
                      let count = 0;
                      item.product_id_list.map((product) => {
                        count += product.count;
                      });

                      return (
                        <tr className="order-item" key={index}>
                          <td>{item.localid}</td>
                          <td className="price">
                            {sliceNumber(item.total_mony)} تومان
                          </td>
                          <td className="count">{count}</td>
                          <td className="status">
                            {item.payed === true
                              ? "پرداخت موفق"
                              : "پرداخت ناموفق"}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </table>
            </div>
          </div>
        </div>

        <div className="information" id="order">
          <div className="title">خرید های انجام شده</div>
          <div className="card">
            <div className="order">
              <table>
                <tr>
                  <th>شماره سفارش</th>
                  <th>قیمت کل</th>
                  <th>تعداد</th>
                  <th>وضعیت</th>
                </tr>
                {order
                  ? order.map((item, index) => {
                      let count = 0;
                      item.product_id_list.map((product) => {
                        count += product.count;
                      });

                      return (
                        <tr className="order-item" key={index}>
                          <td>{item.localid}</td>
                          <td className="price">
                            {sliceNumber(item.total_mony)} تومان
                          </td>
                          <td className="count">{count}</td>
                          <td className="status">
                            {item.payed === true
                              ? "پرداخت موفق"
                              : "پرداخت ناموفق"}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </table>
            </div>
          </div>
        </div>

        <div className="information" id="address">
          <div className="title">آدرس ها</div>
          <div className="card">
            <div className="order" onClick={addressHandler}>
              <table>
                <tr>
                  <th>آدرس ها</th>
                  <th>حذف</th>
                </tr>
                {addresses.map((add, index) => {
                  return (
                    <tr className="order-item" key={index}>
                      <td>{add.address}</td>
                      <td className="deleteIcon" id={index}>
                        <img
                          src={deleteIcon}
                          id={index}
                          className="deleteIconImg"
                          alt=""
                        />
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div className="add-address-button">
            <button onClick={addCommentHandler}>افزودن آدرس</button>
          </div>
        </div>

        <div className="information" id="information">
          <div className="title">اطلاعات حساب</div>
          <div className="card">
            <div className="row">
              <div className="col">
                <label htmlFor="">نام و نام خانوادگی</label>
                <input
                  type="text"
                  defaultValue={profile.name}
                  id="nameProfile"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col">
                <label htmlFor="">پست الکترونیکی</label>
                <input
                  type="text"
                  defaultValue={profile.email}
                  id="emailProfile"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="">شماره تلفن</label>
                <input
                  type="text"
                  defaultValue={profile.phoneNumber}
                  disabled
                />
              </div>
              <div className="col">
                <label htmlFor="">کد ملی</label>
                <input
                  defaultValue={profile.id_code}
                  type="number"
                  id="meliCodeProfile"
                  onChange={(e) => setMeliCode(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="add-address-button">
            <button onClick={updateProfileHandler} id="updateProfileSubmit">
              <span className="buttonTextAgent">ثبت اطلاعات</span>
              <span className="buttonSpinAgent">
                <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin />
              </span>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
