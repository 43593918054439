import React, { useState, useEffect } from "react";
import "./LearnQr.scss";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import bannerLottery from "../../assets/images/selectBanner.png";
import qrCode from "../../assets/images/qrcode.png";
import downloadApp from "../../assets/images/sibApp.png";
import downloadBazzar from "../../assets/images/bazzar.png";

function LearnQr() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <div>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-notFound qr-block">
        <p className="instagram-content" style={{ textAlign: "center" }}>
          <div>ابتدا وارد اپلیکیشن رابط اتحاد شده</div>
          <hr />
          <div className="app-download">
            <a
              href="https://rabetettehad.ir/download/rabet-ettehad.apk"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={downloadApp} alt="downloadApp" />
            </a>
            <a
              href="https://cafebazaar.ir/app/com.rabetettehad"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={downloadBazzar} alt="downloadBazzar" />
            </a>
          </div>
          <div>جهت ثبت کد qr و شرکت در قرعه کشی ابتدا بر روی بنر کلیک کرده</div>
          <hr />
          <img src={bannerLottery} alt="bannerLottery" />
          <div>
            سپس با اسکن کردن کد خود و ثبت آن در قرعه کشی شرکت داده خواهید شد
          </div>
          <hr />
          <img src={qrCode} alt="qrCode" />
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default LearnQr;
