import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import instagramPic from "../../assets/images/instagram-pic.png";
import instagramLogo from "../../assets/images/instagram-logo.png";
import instagramKit from "../../assets/images/instagramKit-service.png";

function Instagram() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  return (
    <div>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-notFound">
        <img src={instagramPic} alt="instagramPic" />
        <p className="instagram-content">
        Instagram contest is one of the best ways to increase real followers, branding and increase the contest rate of Instagram page.
        How to draw Instagram comments is very important to build trust and make your business more visible on social networks, because if the design of the advertising campaign and the contest is done properly, it will cause users to be pessimistic about the brand and business. Be. Your work will be
        </p>
        <p className="instagram-content">
        Instagram contest is one of the best ways to increase real followers, branding and increase the engagement rate of Instagram page. Instagram contest and how to draw Instagram followers' followers is very necessary to build trust and make your business more visible on social networks. Because if the design of the advertising campaign and the contest is not done properly, it will cause users to be pessimistic about your brand and business.
        </p>
        <img src={instagramLogo} alt="instagramLogo" />
        <p className="instagram-content">
        Instagram contest is one of the best ways to increase real followers, branding and increase the engagement rate of Instagram page. Instagram contest and how to draw Instagram followers' followers is very necessary to build trust and make your business more visible on social networks. Because if the design of the advertising campaign and the contest is not done properly, it will cause users to be pessimistic about your brand and business.
        </p>
        <Link className="instagramKitLink" to="/">
        Login to Instagram page
        </Link>
        <img src={instagramKit} alt="instagramKit" className="instagramKit" />
      </div>
      <Footer />
    </div>
  );
}

export default Instagram;
