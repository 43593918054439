import React, { useState, useEffect } from "react";
import "./Rules.scss";

import $ from "jquery";

import { getProductCountBasket } from "../../defz";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

function Privacy() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    $(".submitComment").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <div className="index-rule">
        <div className="title">Privacy</div>
        <p>
        The Alliance interface receives information from the user only to purchase the product, respecting the privacy of the users. To send products, users receive information such as address, phone number and email to process and send the goods as quickly as possible, and in case of any problems, the union interface can identify the buyer's original identity and be accountable to users.
          <br />
          <br />
          Therefore, the registration of addresses, emails and… by the customer is their confirmation and accuracy
 And if this incomplete information is recorded, the alliance interface will contact users for additional information.
          <br />
          <br />
          Users can also use another person's address and phone number to receive the goods to send the order alliance interface to another address.
          <br />
          <br />
          All activities of the Etihad interface website based on e-commerce rules
          Is done and all user information is protected by the Etihad interface.
          <br />
          <br />
          Alliance interface in no way discloses user information to advertising companies
 And may only contact its customers to optimize the website and marketing research.
          <br />
          <br />
          Alliance interface may submit comments in order to comply with the rules of the website
          Edit users. Users while using the services of Max Khodro website,
          The right to edit information and use it in the above framework to interface
          Gives the alliance and deprives it of the right to protest.
          <br />
          <br />
          It is the responsibility of the users to maintain the password and username
Any possible damage resulting from non-compliance with the above is with the user and the user deprives him of the right to make any claim against the alliance interface.
          <br />
          <br />
          Alliance interface, like other websites, uses only IP and cookie collection. Users' personal information is protected by the Etihad liaison site and refuses to provide user information to another person or organization, unless it is obliged to provide it to the competent authorities by a decree of a competent judicial or administrative authority or in accordance with the current laws and regulations of the country. In these cases no liability
 There is no law for compensation for the alliance interface and users with
Expressing their consent to use the services of the website, they have also been deprived of the right to any objection.
          <br />
          <br />
        </p>
        <hr />
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
