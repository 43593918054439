import React, { useState, useEffect } from "react";
import "./Index.scss";
import "./Swiper.css";
import "../../../node_modules/swiper/swiper.scss";

import $ from "jquery";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ProductCard from "../../components/ProductCard/ProductCard";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ForgetPassword from "../../components/SumbitCode/SubmitCode";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

import { Link, useNavigate } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";
import { ImSearch } from "react-icons/im";

import sliderPic1 from "../../assets/images/banner-top-1.png";
import specialOffer from "../../assets/images/specialoffers.png";
import Lottery from "../../assets/images/lottery.png";
import agent from "../../assets/images/agent.png";
import Garanty from "../../assets/svg/garanty.svg";
import Payment from "../../assets/svg/Payment.svg";
import Support from "../../assets/svg/Support.svg";
import Delivery from "../../assets/svg/Delivery.svg";

import { fetchApi, getProductCountBasket } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

SwiperCore.use([Navigation]);

function Index({ token }) {
  const [productCount, setProductCount] = useState(4);
  const [product, setProduct] = useState(4.5);
  const [slider, setSlider] = useState("");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sigIn, setSignIn] = useState(false);
  const [password, setPassword] = useState(false);
  const [specialProducts, setSpecialProducts] = useState([]);
  const [sellProducts, setSellProducts] = useState([]);
  const [offProducts, setOffProducts] = useState([]);

  const history = useNavigate();

  useEffect(() => {
    if (window.innerWidth < 400) {
      setProductCount(1.2);
    } else if (window.innerWidth < 500) {
      setProductCount(1.3);
    } else if (window.innerWidth < 801) {
      setProductCount(2.1);
    }

    if (window.innerWidth < 600) {
      setProduct(1.5);
    } else if (window.innerWidth < 500) {
      setProduct(1.7);
    } else if (window.innerWidth < 801) {
      setProduct(2.6);
    }

    window.innerWidth < 600
      ? $(".swiper-mobile").css({ display: "block" }) &&
        $(".swiper-site").css({ display: "none" })
      : $(".swiper-mobile").css({ display: "none" }) &&
        $(".swiper-site").css({ display: "block" });

    fetchApi("en/api/slider/user/fetch_slider", "", "").then((res) => {
      setSlider(res.slider);
    });

    fetchApi("/en/api/user/fetch_main_screen", "").then((res) => {
      setSpecialProducts(res.special);
      setSellProducts(res.sell);
      setOffProducts(res.chep);
    });
  }, [productCount]);

  const signInHandler = (e) => {
    e.preventDefault();
    $(".cover").css({ display: "block" });
    setSignIn(true);
    setPassword(false);
  };

  const rejectCoverHandler = () => {
    $(".cover").css({ display: "none" });
    setSignIn(false);
    setPassword(false);
    setMobileMenu(false);
  };

  const codeHandler = (status) => {
    if (status === "send") {
      setSignIn(false);
      setPassword(true);
    }
  };

  const statuCodeHandler = (status) => {
    if (status === "set token") {
      $(".cover").css({ display: "none" });
      setSignIn(false);
      setPassword(false);
      setMobileMenu(false);
    }
  };

  const mobileMenuHandler = () => {
    $(".cover").css({ display: "block" });
    setMobileMenu(true);
  };

  return (
    <>
      <div className="cover" onClick={rejectCoverHandler}></div>
      {password ? <ForgetPassword statuCodeHandler={statuCodeHandler} /> : null}
      {mobileMenu ? <MobileMenu /> : null}
      <Header
        handler={signInHandler}
        menuHanlder={mobileMenuHandler}
        basketHandler={getProductCountBasket()}
      />
      <main className="index-main">
        <div className="search-mobile">
          <input
            type="text"
            placeholder="Product Search"
            onClick={(e) => history("/filter/all")}
          />
          <ImSearch className="search-icon" />
        </div>
        <Swiper
          navigation
          className="swiper swiper-site"
          autoplay={{
            delay: 4500,
          }}
          modules={[Autoplay]}
        >
          {slider ? (
            slider.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <img src={item.site_img} className="slide-img" alt="" />
                  </a>
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide>
              <Link to="/">
                <img src={sliderPic1} className="slide-img" alt="" />
              </Link>
            </SwiperSlide>
          )}
        </Swiper>
        <Swiper navigation className="swiper swiper-mobile">
          {slider ? (
            slider.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <img src={item.mobile_img} className="slide-img" alt="" />
                  </a>
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide>
              <Link to="/">
                <img src={sliderPic1} className="slide-img" alt="" />
              </Link>
            </SwiperSlide>
          )}
        </Swiper>
        <div className="special-offers">
        <div className="left">
            <Swiper slidesPerView={productCount} className="swiper">
              {specialProducts
                ? specialProducts.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <ProductCard
                          id={item._id}
                          name={item.title}
                          pic={item.pics[0]}
                          point={item.scoore}
                          percent={parseInt(item.per)}
                          price={item.price}
                          offPrice={item.priceoff}
                          agentPrice={item.priceagent}
                          agentOffPrice={item.priceagentoff}
                        />
                      </SwiperSlide>
                    );
                  })
                : null}
            </Swiper>
          </div>
          <div className="right">
            <img src={specialOffer} alt="" className="specialOfferImage" />
            <Link to="/" className="all-specials">
            <IoIosArrowForward className="arrow" />Show All 
            </Link>
          </div>

        </div>
        <div className="futures">
          <Link to="/instagram" className="lottery">
            <img src={Lottery} alt="Lottery" />
          </Link>
          <Link to="/agentForm" className="lottery">
            <img src={agent} alt="agent" />
          </Link>
        </div>
        <div className="off-products">
          <p className="section-title">Discounted products</p>
          <Swiper slidesPerView={product} className="swiper">
            {offProducts
              ? offProducts.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ProductCard
                        id={item._id}
                        name={item.title}
                        pic={item.pics[0]}
                        point={item.scoore}
                        percent={parseInt(item.per)}
                        price={item.price}
                        offPrice={item.priceoff}
                        agentPrice={item.priceagent}
                        agentOffPrice={item.priceagentoff}
                      />
                    </SwiperSlide>
                  );
                })
              : null}
          </Swiper>
        </div>
        <div className="popular-products">
          <p className="section-title">Best-selling products</p>
          <Swiper slidesPerView={product} className="swiper">
            {sellProducts
              ? sellProducts.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ProductCard
                        id={item._id}
                        name={item.title}
                        pic={item.pics[0]}
                        point={item.scoore}
                        percent={parseInt(item.per)}
                        price={item.price}
                        offPrice={item.priceoff}
                        agentPrice={item.priceagent}
                        agentOffPrice={item.priceagentoff}
                      />
                    </SwiperSlide>
                  );
                })
              : null}
          </Swiper>
        </div>
        <div className="support">
          <img src={Delivery} alt="" />
          <img src={Support} alt="" />
          <img src={Payment} alt="" className="payment-icon" />
          <img src={Garanty} alt="" />
        </div>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
