import React, { useState, useEffect } from "react";
import "./Header.scss";

import { Link, useLocation, useNavigate } from "react-router-dom";

import $ from "jquery";

import Logo from "../../assets/svg/logo.svg";
import fa from '../../assets/images/fa.png'
import { ImSearch, ImFacebook2 } from "react-icons/im";
import { BsBasket2Fill } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import { TiArrowSortedDown } from "react-icons/ti";
import { FaInstagramSquare, FaTelegramPlane } from "react-icons/fa";
import { SiAparat } from "react-icons/si";
import { AiFillTwitterSquare } from "react-icons/ai";

import { fetchApi } from "../../defz";

import { selectUserToken } from "../../redux/Auth/userSelector";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleNotch);

function Header({
  handler,
  menuHanlder,
  token,
  searchHandlerContent,
  basketHandler,
}) {
  const [cat, setCat] = useState("");

  const history = useLocation();
  const navigate = useNavigate();

  const searchHandler = () => {
    if (history.pathname !== "/filter") {
      navigate("/filter/all");
      $("#searchInput").focus();
    }
  };

  useEffect(() => {
    fetchApi(`/api/user/validate`, token, "").then((res) => {
      if (res.status_code === 200) {
        $("#signIn").css({ display: "none" });
        $("#profile").css({ display: "flex" });
      } else if (res.description === "Access denied!") {
        $(".buttonText").css({ display: "flex" });
        $(".buttonSpin").css({ display: "none" });
      }
    });

    fetchApi("en/api/admin/shop/fetch_cat", "", "").then((res) => {
      setCat(res.data);
    });
  }, [token]);

  return (
    <>
      <header>
        <div className="top">
        <div className="left">
          </div>
          <div className="right">
            <div className="input">
              <input
                className="search"
                type="search"
                id="searchInput"
                placeholder="Product Search"
                onClick={searchHandler}
                onChange={(e) => searchHandlerContent(e.target.value)
                }
              />
              <ImSearch className="search-icon" />
            </div>
            <Link to="/">
              <img src={Logo} alt="" className="logo" />
            </Link>
            <div className="hamburger-menu">
              <HiMenu className="menu-icon" onClick={menuHanlder} />
            </div>
          </div>
        </div>
      </header>
      <div className="bottom">
        <div className="header-bottom">
        <div className="left">
            <a href="https://www.telegram.org/rabetettehadco/" className="social-link" target={"_blank"}>
              <FaTelegramPlane className="social-icon telegram-icon" />
            </a>
            <a href="https://www.aparat.com/rabetettehadco/" className="social-link" target={"_blank"}>
              <SiAparat className="social-icon " />
            </a>
            <a href="https://www.twitter.com/rabetettehadco/" className="social-link" target={"_blank"}>
              <AiFillTwitterSquare className="social-icon twitter-icon" />
            </a>
            <a href="https://www.instagram.com/rabetettehadco/" className="social-link" target={"_blank"}>
              <FaInstagramSquare className="social-icon instagram-icon" />
            </a>
            <a href="http://rabetettehad.ir/"> 
            <button className="language-change"><span className="English"> FA </span><img src={fa} alt="english" width="28px" className="english-icon" /> </button>
           </a>
          </div>
          <div className="right">
            <HiMenu className="menu-icon" />
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="ha-drop-down">
                 Products<TiArrowSortedDown className="arrow-down" />
                <div className="drop-down">
                  <ul>
                    {cat
                      ? cat.map((category, index) => {
                          return (
                            <li key={index}>
                              <Link to={`/filter/${category._id}`}>
                                {category.title}
                              </Link>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </li>
              <li className="ha-drop-down">
                 RabetEttehad<TiArrowSortedDown className="arrow-down" />
                <div className="drop-down">
                  <ul>
                  <li>
                      <Link to="/agentForm">Agent</Link>
                    </li>
                    <li>
                      <Link to="/aboutUs">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contactUs">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="https://blog.rabetettehad.ir">Tutorials & announcements</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
