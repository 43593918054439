import React from 'react';
import './App.css';

import { QueryClientProvider, QueryClient } from 'react-query';

import {
  Routes,
  Route
} from "react-router-dom";

import Index from './pages/Index/Index';
import Filter from './pages/Filter/Filter';
import Product from './pages/Product/Product';
import Profile from './pages/Profile/Profile';
import ContactUs from './pages/ContactUs/ContactUs';
import Warranty from './pages/Warranty/Warranty';
import AgentForm from "./pages/AgentForm/AgentForm";
import AboutUs from "./pages/AboutUs/AboutUs";
import SuccessPay from "./pages/Pay/SuccessPay";
import FaildPay from "./pages/Pay/FaildPay";
import Rule from "./pages/Rules/Rules";
import Privacy from "./pages/Rules/Privacy";

import NotFound from "./components/NotFound/NotFound";

import ChoosePlatform from "./pages/Landing/ChoosePlatform";
import Instagram from './pages/Landing/Instagram';
import LearnQr from "./pages/Landing/LearnQr";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path="/" exact index element={<Index />} />
          <Route path="/filter/:id" exact element={<Filter />} />
          <Route path="/product/:id" exact element={<Product />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route path="/contactus" exact element={<ContactUs />} />
          <Route path="/warranty" exact element={<Warranty />} />
          <Route path="/agentForm" exact element={<AgentForm />} />
          <Route path="/aboutUs" exact element={<AboutUs />} />
          <Route path="/successPay" exact element={<SuccessPay />} />
          <Route path="/faildPay" exact element={<FaildPay />} />
          <Route path="/rule" exact element={<Rule />} />
          <Route path="/privacy" exact element={<Privacy />} />
          <Route path="/choosePlatform" exact element={<ChoosePlatform />} />
          <Route path="/instagram" exact element={<Instagram />} />
          <Route path="/qr" exact element={<LearnQr />} />
        </Routes>
      </QueryClientProvider>
    </div>
  );
}

export default App;
